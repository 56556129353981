import moment from "moment";

function momentBase(value, format = "YYYY-MM-DD", fromFormat = '') {
    return moment(value, fromFormat).format(format);
}

function parseDateToFormat(value) {
    return moment(value, ['DD.MM.YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD']).format('DD/MM/YYYY') === value ? value : moment(value).format('DD/MM/YYYY')
}

export {momentBase, parseDateToFormat}