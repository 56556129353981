<template>
  <div class="loader-wrapper" :style="loaderStyle" v-if="!isNaN(this.$props.target)">
    <div id="loader-target" class="h-full"/>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    target: {
      type: String,
      default: null
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    lock: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    spinner: {
      type: String,
      default: null
    },
    background: {
      type: String,
      default: null
    },
    customClass: {
      type: String,
      default: ''
    },
    radius: {
      type: Number,
      default: 22.5
    },
    strokeWidth: {
      type: Number,
      default: 2
    },
    strokeColor: {
      type: String,
      default: '#547181'
    },
    height: {
      type: Number,
      default: 400
    }
  },
  data() {
    return {
      loader: null,
    }
  },
  computed: {
    options() {
      return {
            fullscreen: this.$props.fullscreen,
            lock: this.$props.lock,
            text: this.$props.text,
            spinner: this.$props.spinner,
            background: this.$props.background,
            customClass: this.$props.customClass,
            target: isNaN(this.$props.target) ? this.$props.target : "#loader-target"
      }
    },
    loaderStyle() {
      if(this.$props.loading) {
        return this.$props.fullscreen? '' : `height: ${this.$props.height}px; position: relative; padding-top: 20px`
      }
      return 'display: none'
    }
  },
  mounted() {
    this.loader = this.$loading(this.options);
    let spinner = document.querySelector(`${this.options.target} .el-loading-spinner .circular`);
    spinner.style.width = this.$props.radius * 2 + 'px';
    spinner.style.height = this.$props.radius * 2 + 'px';
    if(spinner && spinner.querySelector('.path')) {
      let spinnerPath = spinner.querySelector('.path')
      spinnerPath.style.strokeWidth = this.$props.strokeWidth
      spinnerPath.style.stroke = this.$props.strokeColor
    }
  },
  watch: {
    loading: function(val) {
      if(val) {
        this.loader.close();
        this.loader = this.$loading(this.options);
        let spinner = document.querySelector(`${this.options.target} .el-loading-spinner .circular`);
        spinner.style.width = this.$props.radius * 2 + 'px';
        spinner.style.height = this.$props.radius * 2 + 'px';
        if(spinner && spinner.querySelector('.path')) {
          let spinnerPath = spinner.querySelector('.path')
          spinnerPath.style.strokeWidth = this.$props.strokeWidth
          spinnerPath.style.stroke = this.$props.strokeColor
        }
      }
      else {
        this.loader.close();
      }
    }
  },
  destroyed() {
    this.loader.close();
  }
}
</script>

<style scoped>

</style>
